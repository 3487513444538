import React from 'react'
import { Link } from 'gatsby'
import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
// import Instagram from "../components/instagram"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

import { Container, Row, Col } from 'react-bootstrap'

export default function Services() {
    return (
        <Layout>
        <SEO title="Home" />
            <div className="masthead white services">
                <Container>
                    <Row><h1>Services</h1></Row>
                </Container>
            </div>
            <Container fluid>
            <div className="services-layout">
                <Row className="interior-detail">
                    <Col className="service-bg detailing"></Col>
                    <Col>
                    {/* Detailing */}
                        <div className="services-description">
                            <h3>Detailing Packages</h3>
                            <p>Here you will find packages to completely restore the look and smell of your vehicle.</p>
                            <Link to="/detailing" className="full-button">Explore</Link>
                        </div> 
                    </Col>
                </Row>
                {/* Paint Enhancement */}
                <Row className="paint reverse">
                    <Col className="service-bg"></Col>
                    <Col>
                        <div className="services-description">
                            <h3>Paint Enhancement</h3>
                            <p>Learn how we can help restore the depth and clarity of your paint.</p>
                            <Link to="/paint-enhancement" className="full-button">Explore</Link>
                        </div> 
                    </Col>
                </Row>                
                {/* Coatings */}
                <Row className="wax">
                    <Col className="service-bg"></Col>
                    <Col>
                        <div className="services-description">
                            <h3>Coating &amp; Waxes</h3>
                            <p>Learn about the different ways we can protect your paint.</p>
                            <Link to="/coating-wax" className="full-button">Explore</Link>
                        </div> 
                    </Col>
                </Row>
                </div>
                </Container>
        </Layout>
    )
}


